import DragDropFile from "@/components/global/DragDropFile.vue";
import { mapState, mapActions } from "vuex";
import { showError } from "@/helpers/globalHelpers";
import moment from "moment";
import { maxValueRule } from "@/helpers/validationList";
export default {
  name: "ObservedUploadEvidenceActionPlan",

  data() {
    return {
      // Tus datos aquí
      loading: false,
      loadingPeriod: false,
      uploadEvidenceForm: {
        evidence_action_plan_id: null,
        evidence_action_plan: [],
        comment: null,
      },
      requiredRules: [(v) => !!v || "El campo es requerido"],
      commentRules: [
        v => !!v || 'El campo es obligatorio',
        (v) => maxValueRule(500, v)], 
    };
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: "Inicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "Dashboard",
          },
        },
        {
          text: "Planes de acción por servicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "ListActionPlanEvidences",
          },
        },
        {
          text: "Planes de acción",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "HomeActionPlans",
            serviceId: parseInt(this.$route.params?.serviceId)
          },
        },
        {
          text: "Evidencia de plan de acción",
          link: true,
          exact: true,
          disabled: false,
          // to: {
          //   name: 'HomeActionPlans',
          // },
        },
      ];
    },
    ...mapState("action_plan", ["actionPlan"]),
    formattedEvidences() {
      return this.actionPlan?.evidences_action_plan.map((evidence) => {
        if (evidence.status === "OBSERVADO") {
          const { dateOnly, isToday } = this.formatDate(evidence.register_date);
          return {
            id: evidence.id,
            register_date: dateOnly,
            isDisabled: !isToday, // Deshabilitar si no es la fecha actual
          };
        }
      });
    },

    evidenceHistoryStatus(){
      const data = this.actionPlan.evidences_action_plan.find(evidenceActionPlan => evidenceActionPlan.id === this.uploadEvidenceForm.evidence_action_plan_id);
      const index = data.evidence_action_plan_history_status.length -1
      return data.evidence_action_plan_history_status[index];
    }
    
  },

  watch: {
    // Observa el estado actionPlan
    actionPlan: {
      immediate: true, // Esto asegura que el observador se ejecute inmediatamente después de la creación del componente
      handler: 'setDefaultEvidenceActionPlanId', // Nombre del método a ejecutar cuando actionPlan cambie
    }
  },

  created() {
    this.getActionPlan();
    this.setDefaultEvidenceActionPlanId();
  },

  methods: {
    // Tus métodos aquí
    ...mapActions("action_plan", ["getActionPlanById"]),
    ...mapActions("evidence_action_plan", ["uploadEvidenceActionPlan"]),

    async getActionPlan() {
      this.loadingPeriod = true;
      const { actionPlanId } = this.$route.params;
      const { error } = await this.getActionPlanById(actionPlanId);
      if (error) showError(error);
      this.loadingPeriod = false;
      this.loading = false;
    },

    async saveEvidenceActionPlan() {
      if (!this.$refs?.form?.validate()) {
        return;
      }
      this.loading = true;
      this.loadingPeriod = false;
      let company_id = localStorage.getItem("company_id");
      const formData = new FormData();

      formData.append(
        "evidence_action_plan_id",
        this.uploadEvidenceForm.evidence_action_plan_id
      );

      formData.append(
        "comment",
        this.uploadEvidenceForm.comment
      );

      this.uploadEvidenceForm.evidence_action_plan.forEach((file) => {
        formData.append("evidence_action_plan", file);
      });

      formData.append("company_id", company_id);

      const { error } = await this.uploadEvidenceActionPlan(formData);

      this.loading = false;

      if (error) {
        showError(error);

        this.loadingPeriod = false;
        this.loading = false;

        this.$nextTick(() => {
          if (this.$refs.form) {
            this.$refs.form.resetValidation();
          }
        });

        this.uploadEvidenceForm = {
          evidence_action_plan_id: null,
          evidence_action_plan: [],
          comment:''
        };
      } else {
        // Muestra un Sweet Alert de éxito
        await this.$swal({
          title: "Evidencia cargada con éxito",
          text: "En un momento te enviaremos la aprobación por correo .",
          icon: "success",
          confirmButtonColor: "#0039a6",
          confirmButtonText: "Volver al inicio",
        });

        this.loadingPeriod = false;
        this.loading = false;

        this.$nextTick(() => {
          if (this.$refs.form) {
            this.$refs.form.resetValidation();
          }
        });

        this.$router.push({ name: "HomeActionPlans",params:{serviceId:parseInt(this.$route.params?.serviceId)} });
      }

      this.uploadEvidenceForm = {
        evidence_action_plan_id: null,
        evidence_action_plan: [],
        comment:''
      };
    },
    cancelUploadEvidence() {
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }
      });

      this.uploadEvidenceForm = {
        evidence_action_plan_id: null,
        evidence_action_plan: [],
        comment:''
      };
      this.$router.push({ name: "HomeActionPlans",params:{serviceId:parseInt(this.$route.params?.serviceId)} });
    },

    formatDate(dateString) {
      // if (!dateString) {
      //   return { dateOnly: "-", isToday: false };
      // }
      // const formattedDateTime = moment(dateString).format("DD/MM/YYYY HH:mm");
      // const dateOnly = formattedDateTime.split(" ")[0];
      // const isToday = moment().format("DD/MM/YYYY") === dateOnly; // Comparar con la fecha actual

      // return { dateOnly, isToday };

      if (!dateString) {
        return { dateOnly: "-", isToday: false };
      }
      // Trata la fecha como un objeto de fecha pura sin conversión de zona horaria
      const dateOnly = moment.utc(dateString).format("DD/MM/YYYY");

      // Compara con la fecha actual en UTC para la consistencia
      const isToday = moment.utc().format("DD/MM/YYYY") === dateOnly;

      return { dateOnly, isToday };
    },

    async setDefaultEvidenceActionPlanId() {
      if (this.actionPlan && this.actionPlan.evidences_action_plan) {
   
      const observedEvidences = this.actionPlan?.evidences_action_plan.filter(evidence => evidence.status === "OBSERVADO");
      if (observedEvidences && observedEvidences.length > 0) {
  
        this.uploadEvidenceForm.evidence_action_plan_id = observedEvidences[0].id;
      }
     }
    },
  },

  components: {
    DragDropFile,
  },
};
