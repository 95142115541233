<template>
	<UploadEvidenceActionPlan />
</template>
<script>

import UploadEvidenceActionPlan from '@/components/action-plans/upload-evidence-action-plan/index.vue'

export default {
	name: 'UploadEvidenceActionPlanPage',
	components: { UploadEvidenceActionPlan },
};
</script>
