var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-container',[_c('v-breadcrumbs',{staticClass:"px-0 pt-2 pb-0",attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])})],1),_c('v-container',[(_vm.actionPlans.length)?_c('v-card',{staticClass:"pa-4 px-md-8 mb-8",attrs:{"rounded":"lg","flat":""}},[_c('v-row',{attrs:{"justify":"space-between","align":"center","dense":""}},[_c('v-col',{attrs:{"cols":"4","md":"8"}},[_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.title))])]),_c('v-col',{attrs:{"cols":"8","md":"4"}},[_c('v-row',{attrs:{"justify":"end"}})],1)],1)],1):_vm._e(),_c('v-data-table',{staticClass:"rounded-lg pa-4 pa-md-8",attrs:{"headers":_vm.headers,"items":_vm.actionPlans,"loading":_vm.loadingActionPlan,"hide-default-footer":"","items-per-page":20},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":item.status !== 'COMPLETADO' ? false : true,"icon":"","to":{
                  name: 'EditActionPlan',
                  params: {
                    actionPlanId: item.id,
                    serviceId: item.service.id,
                  },
                }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":item.status !== 'COMPLETADO' ? false : true,"icon":"","to":{
                  name: 'UploadEvidenceActionPlan',
                  params: {
                    actionPlanId: item.id,
                    serviceId: item.service.id,
                  },
                }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-cloud-upload ")])],1)]}}],null,true)},[_c('span',[_vm._v("Subir evidencia")])]),(_vm.evidencesObserved(item))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":item.status !== 'COMPLETADO' ? false : true,"icon":"","to":{
                  name: 'ObservedUploadEvidenceActionPlan',
                  params: {
                    actionPlanId: item.id,
                    serviceId: item.service.id,
                  },
                }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-cloud-refresh ")])],1)]}}],null,true)},[_c('span',[_vm._v("Subir observaciones")])]):_vm._e(),(
              item.status !== 'COMPLETADO' &&
              item.status !== 'ATRASADO' &&
              _vm.isAllowed(
                'climaLaboral',
                'listaPlanesAccion',
                'eliminarPlanAccion'
              )
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.confirmDelete(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar ")])]):_vm._e()],1)]}},{key:`item.status`,fn:function({ item }){return [_c('v-chip',{attrs:{"color":_vm.setColorStatusActionPlan(item?.status).background,"text-color":_vm.setColorStatusActionPlan(item?.status).color}},[_vm._v(" "+_vm._s(_vm.setColorStatusActionPlan(item?.status).name)+" ")])]}}],null,true)})],1),_c('div',[_c('v-pagination',{attrs:{"length":_vm.actionPlansPagination.totalPages},on:{"input":() => _vm.getActionPlan()},model:{value:(_vm.actionPlanFilters.page),callback:function ($$v) {_vm.$set(_vm.actionPlanFilters, "page", $$v)},expression:"actionPlanFilters.page"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }