<template>
	<DashboardActionPlan />
</template>
<script>

import DashboardActionPlan from '@/components/action-plans/dashboard-action-plan/index.vue'

export default {
	name: 'DashboardActionPlanPage',
	components: { DashboardActionPlan },
};
</script>
