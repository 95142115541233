<template>
	<ReportActionPlan />
</template>
<script>

import ReportActionPlan from '@/components/action-plans/report-action-plan/index.vue'

export default {
	name: 'ReportActionPlanPage',
	components: { ReportActionPlan },
};
</script>
