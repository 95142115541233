import { mapActions, mapState } from "vuex";
import { showError, hasItems } from "@/helpers/globalHelpers";
import moment from 'moment';
import pdf from '@teckel/vue-pdf'

export default {
  name: "DetailActionPlanSatisfactionGroupedService",
  data() {
    return {
      colors: [
        'indigo',
        'warning',
        'pink darken-2',
        'red lighten-1',
        'deep-purple accent-4',
      ],
      slides: [
        'First',
        'Second',
        'Third',
        'Fourth',
        'Fifth',
      ],
      isLoading: false,
      actionPlans: [],
      searchTimeout: null,
      selectedFile: null,
      searchValue: "",
      generalProgress: {},
      tab: 0,
      modalOpen: false,
      modalItem: null,
      monthtHeaders: [
        { text: "Año", sortable: false, value: "period" },
        { text: "Mes", sortable: false, value: "period_month" },
        {
          text: "Tareas programadas",
          sortable: false,
          value: "scheduledTasks",
        },
        { text: "Tareas ejecutadas", sortable: false, value: "executedTasks" },
        { text: "% de avance", sortable: false, value: "progress" },
      ],
      actionPlanHeaders: [
        {
          text: 'Plan de acción',
          align: 'start',
          sortable: false,
          value: 'description'
        },
        {
          text: 'Estado',
          align: 'start',
          sortable: false,
          value: 'status'
        },
        // {
        //   text:'Fecha de vencimiento',
        //   align:'start',
        //   sortable:false,
        //   value:''
        // },
        {
          text: 'Gerencia',
          align: 'start',
          sortable: false,
          value: 'management.name'
        },
        {
          text: 'Servicio',
          align: 'start',
          sortable: false,
          value: 'service.name'
        },
        {
          text: 'Responsable',
          align: 'start',
          sortable: false,
          value: 'responsible.fullName'
        },
        {
          text: 'Tareas programadas',
          align: 'start',
          sortable: false,
          value: 'generalProgress.totalScheduledTasks'
        },
        {
          text: 'Tareas ejecutadas',
          align: 'start',
          sortable: false,
          value: 'generalProgress.totalExecutedTasks'
        },
        {
          text: '% de avance',
          align: 'start',
          sortable: false,
          value: 'generalProgress.progress'
        },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      singleExpand: true,
      expanded: [],
      status: [
        // { name: "EN CURSO", value: "EN_CURSO" },
        { name: "COMPLETADO", value: "COMPLETADO" },
        { name: "PENDIENTE", value: "PENDIENTE" },
        { name: "NO COMPLETADO", value: "NO_COMPLETADO" },
        { name: "OBSERVADO", value: "OBSERVADO" },
      ],
      evidenceActionPlanHeaders: [
        { text: 'Periodo', align: 'center', sortable: false, value: 'register_date' },
        { text: 'Estado', align: 'center', sortable: false, value: 'status' },
        { text: 'Fecha de subida de evidencia', align: 'center', sortable: false, value: 'upload_date' },
        { text: "Evidencias", value: "actions", align: 'center', sortable: false },
      ],
      actionPlansPagination: { totalPages: 0 },
      actionPlanServiceFilters: {
        page: 1,
        limit: process.env.VUE_APP_PAGINATION_PER_PAGE || 20,
        pagination: true,
        service_ids: this.$route.params.serviceId,
        // status: null,
        status_evidence_action_plan: ['PENDIENTE', 'NO_COMPLETADO', 'OBSERVADO'],
        responsible: null,
        month: parseInt(moment().format('M')),
      },
      monthValues: [
        { name: "ENERO", value: 1 },
        { name: "FEBRERO", value: 2 },
        { name: "MARZO", value: 3 },
        { name: "ABRIL", value: 4 },
        { name: "MAYO", value: 5 },
        { name: "JUNIO", value: 6 },
        { name: "JULIO", value: 7 },
        { name: "AGOSTO", value: 8 },
        { name: "SETIEMBRE", value: 9 },
        { name: "OCTUBRE", value: 10 },
        { name: "NOVIEMBRE", value: 11 },
        { name: "DICIEMBRE", value: 12 },
      ],
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "Inicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "Dashboard",
          },
        },
        {
          text: "Lista de planes de acción por servicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "ReportActionPlansSatisfaction",
          },
        },
        {
          // text: `Detalle de agrupación de planes de acción por ${this.service} ${this.gerencia}`,
          text: `Detalle de agrupación de planes de acción por servicio`,
          link: true,
          exact: true,
          disabled: false,
        },
      ];
    },
    ...mapState("auth", ["user"]),
    ...mapState("security", [
      "costCentersUser",
      "managementsUser"
    ]),
    ...mapState("security", ["usersActive"]),
    // ...mapState("action_plan", ["generalProgress", ]),
    managementDetails() {
      const { serviceId } = this.$route.params;
      const serviceIdNumber = parseInt(serviceId);

      if (this.managementsUser) {
        for (const management of this.managementsUser) {
          for (const costCenter of management.costCenters) {
            if (costCenter.id === serviceIdNumber) {
              return {
                managementName: management.name,
                costCenterName: costCenter.name
              };
            }
          }
        }
      }
      return null;
    }
  },
  created() {
    this.getActionPlanByService();
    this.getCostCenterByUser();
    this.loadUsers();
  },
  watch: {
    modalOpen(newVal) {
      if (!newVal) {
        this.selectedFile = null;
        this.modalItem = null;
      }
    }
  },
  methods: {
    ...mapActions("action_plan", ["listActionPlanPagination"]),
    ...mapActions("security", [
      "getCostCenterUser", "getUsersActive"
    ]),

    hasItems,
    loadUsers: async function () {
      this.isLoadingUsers = true;
      await this.getUsersActive();
      this.isLoadingUsers = false;
    },
    async getCostCenterByUser() {
      const { error } = await this.getCostCenterUser({
        user_id: this.user.id,
      });
      if (error) showError(error);
    },

    setNameMonth(month) {
      const months = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Setiembre', 'Octubre', 'Noviembre', 'Diciembre'
      ];
      return months[new Date(`${month} 1`).getMonth()] || month;
    },

    async getEvidenceActionPlan() {
      this.loadingActionPlan = true;

      const filters = { ...this.evidenceActionPlanFilters, };

      if (filters.status && filters.status.length > 0) {
        filters.status = filters.status.join(',');
      } else {
        delete filters.status;
      }
      console.log(filters)
      const { error } = await this.listActionPlanPagination(filters);
      if (error) showError(error);
      this.loadingActionPlan = false;
    },

    async getActionPlanByService() {

      this.isLoading = true;
      const { serviceId } = this.$route.params;

      const filters = { ...this.actionPlanServiceFilters, type: 'SATISFACCION_CLIENTE' };
      filters.service_ids = serviceId;


      if (filters.status_evidence_action_plan && filters.status_evidence_action_plan.length > 0) {
        filters.status_evidence_action_plan = filters.status_evidence_action_plan.join(',');
      } else {
        delete filters.status_evidence_action_plan;
      }
      const { error, response } = await this.listActionPlanPagination(filters);
      if (response && response.data && response.generalProgress) {
        this.actionPlans = response.data;
        this.generalProgress = response.generalProgress;
      } else if (error) {
        showError(error);
      }
      this.isLoading = false;
    },
    formatDate(dateString) {

      if (!dateString) {
        return "-";
      }

      const dateOnly = moment.utc(dateString).format("DD/MM/YYYY");

      return dateOnly;
    },
    debounceSearch() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.actionPlanServiceFilters.description = this.searchValue;
        this.getActionPlanByService();
      }, 800);
    },
    setColorStatusDetailActionPlan(status) {
      switch (status) {
        case "EN_CURSO":
          return {
            background: "blue lighten-5",
            color: "blue",
            name: "EN CURSO",
          };
        case "PENDIENTE":
          return {
            background: "blue lighten-5",
            color: "blue",
            name: "PENDIENTE",
          }
        case "COMPLETADO":
          return {
            background: "green lighten-5",
            color: "green",
            name: status,
          };
        case "MODIFICADO":
          return {
            background: "yellow lighten-5",
            color: "yellow",
            name: status,
          };
        case "OBSERVADO":
          return {
            background: "yellow lighten-4",
            color: "yellow darken-2",
            name: status,
          };
        case "NO_COMPLETADO":
          return {
            background: "grey lighten-3",
            color: "grey",
            name: "NO COMPLETADO",
          };
      }
    },
    isImage(file) {
      const extension = this.getFileExtension(file);
      return ['jpg', 'jpeg', 'png', 'gif'].includes(extension);
    },
    isPDF(file) {
      const extension = this.getFileExtension(file);
      return extension === 'pdf';
    },
    getFileExtension(file) {
      const url = new URL(file);
      const pathname = url.pathname;
      const parts = pathname.split('.');
      return parts[parts.length - 1].toLowerCase();
    },
    allImages(evidences) {
      return evidences?.every(item => this.isImage(item.evidence_file));
    },
    hasImageOrPDF(evidences) {
      return evidences?.some(item => this.isImage(item.evidence_file) || this.isPDF(item.evidence_file));
    },
    openModal(item) {
      try {
        this.modalItem = item;
        this.modalOpen = true;
      } catch (error) {
        console.error("Error opening modal:", error);
      }
    },
    closeModal() {
      this.modalOpen = false;
    },
    viewFile(file) {
      try {
        if (this.isImage(file) || this.isPDF(file)) {
          this.selectedFile = file;
        }
      } catch (error) {
        console.error("Error viewing file:", error);
      }
    },
    closeFileView() {
      this.selectedFile = null;
    },
  },
  components: {
    pdf
  },
};