import { mapActions } from 'vuex';
import { showError } from "@/helpers/globalHelpers";
export default {
    name: 'ObservedEvidenceDialog',
    data() {
        return {
            loadingSave: false,
            valid: false,
            listError: [],
            commentRules: [
                (v) => !!v || 'Comentario es requerido',
                // (v) => v.length <= 400 || 'Comentario debe tener menos de 400 caracteres',
            ],
            confirmObservedForm: {
                company_id: null,
                evidence_action_plan_id: null,
                observed: true,
                comment: null
            }
        };
    },
    props: {
        value: Boolean,
        item: Object,
        source: String,
    },
    created() {

    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    methods: {
        ...mapActions('evidence_action_plan', ['confirmObservedEvidenceActionPlan']),
        close() {
            this.dialog = false;
            this.$refs?.form?.resetValidation();
            this.listError = [];
        },

        async confirmObservedEvidence() {
            if (!this.$refs?.form?.validate()) {
                return;
            }
            this.loadingSave = true;
            this.confirmObservedForm.company_id = parseInt(localStorage.getItem("company_id"));
            this.confirmObservedForm.observed = true;
            this.confirmObservedForm.evidence_action_plan_id = this.item.id;

            const { error } = await this.confirmObservedEvidenceActionPlan(this.confirmObservedForm);

            if (this.source !== 'evidenceActionPlan') {
                await this.$emit('getEvidenceActionPlan');
            } else {
                this.$router.push({ name: "ListEvidenceActionPlan" })
            }


            if (error) showError(error);

            this.loadingSave = false;


            this.$nextTick(() => {
                if (this.$refs.form) {
                    this.$refs.form.resetValidation();
                }
            });

            this.confirmObservedForm = {
                company_id: '',
                evidence_action_plan_id: '',
                // observed: '',
                comment: ''
            }

            this.dialog = false;
        },

        dialogInput(value) {
            this.$nextTick(() => {
                this.$refs.dialogForm.$el.blur();
                this.$refs.form.resetValidation();
                this.dialog = value;
                if (!value) {
                    this.confirmObservedForm = {
                        company_id: '',
                        evidence_action_plan_id: '',
                        // observed: true,
                        comment: ''
                    }

                }
            })
        },
    },
};
