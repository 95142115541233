<template>
	<ReportActionPlanSatisfaction />
</template>
<script>

import ReportActionPlanSatisfaction from '@/components/satisfaction-action-plans/report-action-plan-satisfaction/index.vue'

export default {
	name: 'ReportActionPlanSatisfactionPage',
	components: { ReportActionPlanSatisfaction },
};
</script>
