<template>
	<DashboardActionPlanSatisfaction />
</template>
<script>

import DashboardActionPlanSatisfaction from '@/components/satisfaction-action-plans/dashboard-action-plan-satisfaction/index.vue'

export default {
	name: 'DashboardActionPlanSatisfactionPage',
	components: { DashboardActionPlanSatisfaction },
};
</script>
