<template>
	<ObservedUploadEvidenceActionPlan />
</template>
<script>

import ObservedUploadEvidenceActionPlan from '@/components/action-plans/observed-upload-evidence-action-plan/index.vue'

export default {
	name: 'ObservedUploadEvidenceActionPlanPage',
	components: { ObservedUploadEvidenceActionPlan },
};
</script>
