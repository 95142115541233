<template>
	<DetailActionPlanGroupedService />
</template>
<script>

import DetailActionPlanGroupedService from '@/components/action-plans/detail-action-plan-grouped-service/index.vue'

export default {
	name: 'DetailActionPlanGroupedServicePage',
	components: { DetailActionPlanGroupedService },
};
</script>
