import { mapState, mapActions } from "vuex";
import { showError, hasItems } from "@/helpers/globalHelpers";
import ActionPlanItem from "@/components/action-plans/components/action-plan-item/index.vue";
import moment from 'moment';
import pdf from '@teckel/vue-pdf'

export default {
  name: "ActionPlansTab",
  data() {
    return {
      isLoading: false,
      searchTimeout: null,
      searchValue: "",
      actionPlans: [],
      actionPlansPagination: { totalPages: 0 },
      monthValues: [
        { name: "ENERO", value: 1 },
        { name: "FEBRERO", value: 2 },
        { name: "MARZO", value: 3 },
        { name: "ABRIL", value: 4 },
        { name: "MAYO", value: 5 },
        { name: "JUNIO", value: 6 },
        { name: "JULIO", value: 7 },
        { name: "AGOSTO", value: 8 },
        { name: "SETIEMBRE", value: 9 },
        { name: "OCTUBRE", value: 10 },
        { name: "NOVIEMBRE", value: 11 },
        { name: "DICIEMBRE", value: 12 },
      ],
      actionPlanServiceFilters: {
        page: 1,
        limit: process.env.VUE_APP_PAGINATION_PER_PAGE || 20,
        pagination: true,
        service_ids:this.$route.params.serviceId,
        // status: null,
        status_evidence_action_plan: 'PENDIENTE',
        responsible: null,
        month: parseInt(moment().format('M')),
      },
      status: [
        // { name: "EN CURSO", value: "EN_CURSO" },
        { name: "COMPLETADO", value: "COMPLETADO" },
        { name: "PENDIENTE", value: "PENDIENTE" },
        { name: "NO COMPLETADO", value: "NO_COMPLETADO" },
        { name: "OBSERVADO", value: "OBSERVADO" },
      ],
      actionPlanHeaders:[
        {
          text:'Plan de acción',
          align:'start',
          sortable:false,
          value:'description'
        },
        {
          text:'Estado',
          align:'start',
          sortable:false,
          value:'status'
        },
        // {
        //   text:'Fecha de vencimiento',
        //   align:'start',
        //   sortable:false,
        //   value:''
        // },
        {
          text:'Gerencia',
          align:'start',
          sortable:false,
          value:'management.name'
        },
        {
          text:'Servicio',
          align:'start',
          sortable:false,
          value:'service.name'
        },
        {
          text:'Responsable',
          align:'start',
          sortable:false,
          value:'responsible.fullName'
        },
        {
          text:'Tareas programadas',
          align:'start',
          sortable:false,
          value:'generalProgress.totalScheduledTasks'
        },
        {
          text:'Tareas ejecutadas',
          align:'start',
          sortable:false,
          value:'generalProgress.totalExecutedTasks'
        },
        {
          text:'% de avance',
          align:'start',
          sortable:false,
          value:'generalProgress.progress'
        },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      // headers:[
      //   { text: '', value: 'data-table-expand' },
      //   { text:'Periodo', align:'start', sortable:false, value:'register_date'},
      //   { text:'Estado', align:'start', sortable:false, value:'status' },
      //   { text:'Fecha de subida de evidencia', align:'start', sortable:false, value: '-'}
      // ],
      evidenceActionPlanHeaders:[
        { text:'Periodo', align:'center', sortable:false, value:'register_date'},
        { text:'Estado', align:'center', sortable:false, value:'status'},
        { text:'Fecha de subida de evidencia', align:'center', sortable:false, value: 'upload_date'},
        { text: "Evidencias", value: "actions", align:'center', sortable: false },
      ],
      expanded: [],
      singleExpand: true,
      modalOpen: false,
      modalItem: null,
      selectedFile: null
    };
  },
  computed: {
    ...mapState("security", ["usersActive"]),
  },
  created() {
   
    this.loadUsers();
    this.getActionPlanByService();
  },
  watch: {
    modalOpen(newVal) {
      if (!newVal) {
        this.selectedFile = null;
        this.modalItem = null;
      }
    }
  },
  methods: {
    ...mapActions("security", ["getUsersActive"]),
    ...mapActions("action_plan", ["listActionPlanPagination"]),
    hasItems,
    loadUsers: async function () {
      this.isLoadingUsers = true;
      await this.getUsersActive();
      this.isLoadingUsers = false;
    },
    isImage(file) {
      const extension = this.getFileExtension(file);
      return ['jpg', 'jpeg', 'png', 'gif'].includes(extension);
    },
    isPDF(file) {
      const extension = this.getFileExtension(file);
      return extension === 'pdf';
    },
    getFileExtension(file) {
      const url = new URL(file);
      const pathname = url.pathname;
      const parts = pathname.split('.');
      return parts[parts.length - 1].toLowerCase();
    },
    openModal(item) {
      try {
        this.modalItem = item;
        this.modalOpen = true;
      } catch (error) {
        console.error("Error opening modal:", error);
      }
    },
    closeModal(){
      this.modalOpen = false;
    },
    viewFile(file) {
      try {
        if(this.isImage(file) || this.isPDF(file)) {
          this.selectedFile = file;
        }
      } catch (error) {
        console.error("Error viewing file:", error);
      }
    },
    closeFileView(){
      this.selectedFile = null;
    },
    async getActionPlanByService() {
      this.isLoading = true;
      try {
        const { ok, error, response } = await this.listActionPlanPagination(this.actionPlanServiceFilters);
        if (!ok) {
          showError(error);
        } else {
          this.actionPlans = response.data;
          this.actionPlansPagination = response.pagination;
        }
      } catch (error) {
        showError(error);
      } finally {
        this.isLoading = false;
      }
    },
    debounceSearch() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.actionPlanServiceFilters.description = this.searchValue;
        this.getActionPlanByService();
      }, 800);
    },
    formatDate(dateString) {

      if (!dateString) {
        return "-";
      }

      const dateOnly = moment.utc(dateString).format("DD/MM/YYYY");

      return dateOnly;
    },
    setColorStatusDetailActionPlan(status) {
      switch (status) {
        case "EN_CURSO":
          return {
            background: "blue lighten-5",
            color: "blue",
            name: "EN CURSO",
          };
        case "PENDIENTE":
          return {
            background: "blue lighten-5",
            color: "blue",
            name: "PENDIENTE",
          }
        case "COMPLETADO":
          return {
            background: "green lighten-5",
            color: "green",
            name: status,
          };
        case "OBSERVADO":
          return {
            background: "yellow lighten-4",
            color: "yellow darken-2",
            name: status,
          };
        case "NO_COMPLETADO":
          return {
            background: "grey lighten-3",
            color: "grey",
            name: "NO COMPLETADO",
          };
      }
    },
  },
  components: {
    ActionPlanItem,
    pdf
  },
};