<template>
	<CreateActionPlan />
</template>
<script>

import CreateActionPlan from '@/components/action-plans/create-action-plan/index.vue'

export default {
	name: 'CreateActionPlanPage',
	components: { CreateActionPlan },
};
</script>
