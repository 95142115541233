import DragDropFile from "@/components/global/DragDropFile.vue";
import { mapState, mapActions } from "vuex";
import { showError } from "@/helpers/globalHelpers";
import moment from "moment";
import { maxValueRule } from "@/helpers/validationList";
export default {
  name: "UploadEvidenceActionPlanSatisfaction",

  data() {
    return {
      loading: false,
      loadingPeriod: false,
      loadingDeleteEvidence: false,
      uploadEvidenceForm: {
        evidence_action_plan_id: null,
        evidence_action_plan: [],
        comment: null,
      },
      requiredRules: [(v) => !!v || "El campo es requerido"],
      commentRules: [
        v => !!v || 'El campo es obligatorio',
        (v) => maxValueRule(500, v)],
    };
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: "Inicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "Dashboard",
          },
        },
        {
          text: "Planes de acción",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "ListActionPlanEvidencesSatifaction",
          },
        },
        {
          text: "Cargar Evidencia",
          link: true,
          exact: true,
          disabled: false,
        },
      ];
    },
    ...mapState("evidence_action_plan", ["evidenceActionPlan"]),
    commentObservation() {
      if (!this.evidenceActionPlan || !this.evidenceActionPlan?.evidence_action_plan_history_status || this.evidenceActionPlan?.evidence_action_plan_history_status.length === 0) {
        return null;
      }

      const historyStatus = this.evidenceActionPlan?.evidence_action_plan_history_status;
      const lastElement = historyStatus[historyStatus.length - 1];
      return lastElement.comment;
    },

    setButtonName() {
      if (this.evidenceActionPlan?.status === 'COMPLETADO' &&
        this.isAllowed(
          'satisfaccionCliente',
          'listaPlanesAccion',
          'eliminarEvidencias')) {
            return 'Cancelar';
      }else if(this.evidenceActionPlan?.status === 'COMPLETADO'){
        return 'Atras'
      }else{
        return 'Cancelar'
      }
    },
  },

  created() {
    this.getActionPlan();
  },

  methods: {
    ...mapActions("action_plan", [
      "getActionPlanById"
    ]),
    ...mapActions("evidence_action_plan", [
      "uploadEvidenceActionPlan",
      "getEvidenceActionPlanById",
      "cleanEvidenceActionPlanId",
      "deleteEvidenceActionPlan"
    ]),

    async getActionPlan() {
      this.loadingPeriod = true;
      const { evidenceId } = this.$route.params;
      const { error } = await this.getEvidenceActionPlanById(evidenceId);
      if (error) showError(error);
      this.loadingPeriod = false;
      this.loading = false;
    },

    async saveEvidenceActionPlan() {
      if (!this.$refs?.form?.validate()) {
        return;
      }
      this.loading = true;
      this.loadingPeriod = false;
      let company_id = localStorage.getItem("company_id");
      const formData = new FormData();

      const evidenceId = this.$route.params.evidenceId;

      this.uploadEvidenceForm.evidence_action_plan_id = evidenceId;

      formData.append(
        "evidence_action_plan_id",
        this.uploadEvidenceForm.evidence_action_plan_id
      );

      formData.append(
        "comment",
        this.uploadEvidenceForm.comment
      );

      this.uploadEvidenceForm.evidence_action_plan.forEach((file) => {
        formData.append("evidence_action_plan", file);
      });

      formData.append("company_id", company_id);

      const { error } = await this.uploadEvidenceActionPlan(formData);

      this.loading = false;

      if (error) {
        showError(error);

        this.loadingPeriod = false;
        this.loading = false;

        this.$nextTick(() => {
          if (this.$refs.form) {
            this.$refs.form.resetValidation();
          }
        });

        this.uploadEvidenceForm = {
          evidence_action_plan_id: null,
          evidence_action_plan: [],
          comment: ''
        };
      } else {
        await this.$swal({
          title: "Evidencia cargada con éxito",
          text: "En un momento te enviaremos la aprobación por correo .",
          icon: "success",
          confirmButtonColor: "#0039a6",
          confirmButtonText: "Volver al inicio",
        });

        this.loadingPeriod = false;
        this.loading = false;

        this.$nextTick(() => {
          if (this.$refs.form) {
            this.$refs.form.resetValidation();
          }
        });

        this.$router.push({ name: "ListActionPlanEvidencesSatifaction" });
      }

      this.uploadEvidenceForm = {
        evidence_action_plan_id: null,
        evidence_action_plan: [],
        comment: ''
      };
    },
    cancelUploadEvidence() {
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }
      });

      this.uploadEvidenceForm = {
        evidence_action_plan_id: null,
        evidence_action_plan: [],
        comment: ''
      };
      this.$router.push({ name: "ListActionPlanEvidencesSatifaction" });
    },

    formatDate(dateString) {

      if (!dateString) {
        return "-";
      }

      const dateOnly = moment.utc(dateString).format("DD/MM/YYYY");

      return dateOnly;
    },
    setColorStatusDetailActionPlan(status) {
      switch (status) {
        case "EN_CURSO":
          return {
            background: "blue lighten-5",
            color: "blue",
            name: "EN CURSO",
          };
        case "PENDIENTE":
          return {
            background: "blue lighten-5",
            color: "blue",
            name: "PENDIENTE",
          }
        case "COMPLETADO":
          return {
            background: "green lighten-5",
            color: "green",
            name: status,
          };
        case "OBSERVADO":
          return {
            background: "yellow lighten-4",
            color: "yellow darken-2",
            name: status,
          };
        case "NO_COMPLETADO":
          return {
            background: "grey lighten-3",
            color: "grey",
            name: "NO COMPLETADO",
          };
      }
    },

    async deleteEvidence() {
      this.$swal({
        text: '¿Estás seguro de borrar esta evidencia?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0039a6',
        cancelButtonColor: 'grey',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sí',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loadingDeleteEvidence = true;
          const { evidenceId } = this.$route.params;
          const { error } = await this.deleteEvidenceActionPlan(evidenceId);
          if (error) showError(error);
          this.loadingDeleteEvidence = false;
          await this.getActionPlan()
        }
      })
    },

    //[TO DO, mover a helper]
    isAllowed(module, page, activityName) {

      const activities = this.$store.state.auth.activities || [];
      const found = activities.some(
        (activity) =>
          activity.module_name === module &&
          activity.page_name === page &&
          activity.activity_name === activityName
      );

      return found;
    },
  },

  beforeDestroy() {
    this.cleanEvidenceActionPlanId();
  },

  components: {
    DragDropFile,
  },
};
