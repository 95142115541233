<template>
	<CreateActionPlanSatisfaction />
</template>
<script>

import CreateActionPlanSatisfaction from '@/components/satisfaction-action-plans/create-action-plan-satisfaction/index.vue'

export default {
	name: 'CreateActionPlanSatisfactionPage',
	components: { CreateActionPlanSatisfaction },
};
</script>
