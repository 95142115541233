<template>
	<DetailActionPlanSatisfaction />
</template>
<script>

import DetailActionPlanSatisfaction from '@/components/satisfaction-action-plans/detail-action-plan-satisfaction/index.vue'

export default {
	name: 'DetailActionPlanSatisfactionPage',
	components: { DetailActionPlanSatisfaction },
};
</script>
