<template>
	<EditActionPlanSatisfaction />
</template>
<script>

import EditActionPlanSatisfaction from '@/components/satisfaction-action-plans/edit-period-action-plan-satisfaction/index.vue'

export default {
	name: 'EditActionPlanSatisfactionPage',
	components: { EditActionPlanSatisfaction },
};
</script>
