<template>
	<DetailEvidenceActionPlanSatisfaction />
</template>
<script>

import DetailEvidenceActionPlanSatisfaction from '@/components/satisfaction-action-plans/detail-evidence-action-plan-satisfaction/index.vue'

export default {
	name: 'DetailEvidenceActionPlanSatisfactionPage',
	components: { DetailEvidenceActionPlanSatisfaction },
};
</script>
