import { mapState, mapActions } from "vuex";
import { showError } from "@/helpers/globalHelpers";
import { Bar } from 'vue-chartjs';
import { Chart, registerables } from 'chart.js';
import moment from "moment";


Chart.register(...registerables);

export default {
  name: 'DetailPeriodUpdateRequest',
  data: () => ({
    filteredCostCenters: [],
    tab: 0,
    chartData1: {
      labels: [],
      datasets: [],
    },
    chartData2: {
      labels: [],
      datasets: [],
    },
    chartData3: {
      labels: [],
      datasets: [],
    },
    chartData4: {
      labels: [],
      datasets: [],
    },
    chartOptions1: {

      scales: {
        y: {
          beginAtZero: true,
          max: 100,
          ticks: {
            callback: function (value) {
              return value + "%";
            }
          }
        }
      },
      plugins: {
        legend: {
          display: true,
          position: 'bottom'
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              return tooltipItem.raw + "%";
            }
          }
        },
      }
    },
    chartOptions2: {
      scales: {
        y: {
          beginAtZero: true,
          max: 100,
          ticks: {
            callback: function (value) {
              return value + "%";
            }
          }
        }
      },
      plugins: {
        legend: {
          display: true,
          position: 'bottom'
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              return tooltipItem.raw + "%";
            }
          }
        },
        // customAnnotations: {
        //   totalAmounts: []
        // },
      }
    },
    chartOptions4: {
      scales: {
        y: {
          beginAtZero: true,
          max: 100,
          ticks: {
            callback: function (value) {
              return value + "%";
            }
          }
        }
      },
      plugins: {
        legend: {
          display: true,
          position: 'bottom'
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              return tooltipItem.raw + "%";
            }
          }
        },
        // customAnnotations: {
        //   totalAmounts: []
        // },
      }
    },
    isLoadingData: false,
    months: [
      { name: 'Enero', value: 1 },
      { name: 'Febrero', value: 2 },
      { name: 'Marzo', value: 3 },
      { name: 'Abril', value: 4 },
      { name: 'Mayo', value: 5 },
      { name: 'Junio', value: 6 },
      { name: 'Julio', value: 7 },
      { name: 'Agosto', value: 8 },
      { name: 'Septiembre', value: 9 },
      { name: 'Octubre', value: 10 },
      { name: 'Noviembre', value: 11 },
      { name: 'Diciembre', value: 12 },
    ],
    managementTypes: [
      { name: 'Gerencia', value: 'management' },
      { name: 'Area', value: 'area' }
    ],
    currentMonth: new Date().getMonth() + 1,
    selectedMonth: parseInt(moment().format('M')),
    selectedManagement: null,
    selectedService: null,
    selectedManagementType: null,
    filteredManagements: [],
  }),

  watch: {
    selectedMonth(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateURL();
      }
    },
    selectedManagement(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateURL();
      }
    },
  },

  created() {
    this.getCostCenterByUser();
    this.initializeFiltersFromQuery();
    // this.loadData();
    this.addCustomPlugin();
  },

  computed: {
    headers() {
      const monthName = this.findValueMonth(this.selectedMonth)?.name || '';
      return [
        { text: 'Servicio', value: 'serviceName' },
        { text: `N° de acciones programadas Enero - ${monthName}`, value: 'tasksUntilSelectedMonth' },
        { text: `% programado Enero - ${monthName}`, value: 'completionPercentage' },
        { text: `N° de acciones ejecutadas Enero - ${monthName}`, value: 'completedTasksUntilSelectedMonth' },
        { text: `% ejecutado Enero - ${monthName}`, value: 'executionPercentage' },
        { text: 'Plan de acción anual totales', value: 'totalAnnualTasks' },
      ];
    },
    serviceComplianceHeaders() {
      const monthName = this.findValueMonth(this.selectedMonth)?.name || '';
      return [
        { text: 'Nombre del Servicio', value: 'serviceName' },
        { text: `Total Acciones Ejecutadas (Enero - ${monthName})`, value: 'completedTasks' },
        { text: `Porcentaje de Cumplimiento (Enero - ${monthName})`, value: 'completionPercentage' },
      ];
    },
    complianceByManagementHeaders() {
      const monthName = this.findValueMonth(this.selectedMonth)?.name || '';
      return [
        { text: 'Gerencia', value: 'managementName' },
        { text: `N° acciones ejecutadas Enero - ${monthName}`, value: 'totalCompleted' },
        { text: `% de cumplimiento Enero - ${monthName}`, value: 'overallCompletionPercentage' },
      ];
    },
    plannedVsRealComplianceHeaders() {
      const monthName = this.findValueMonth(this.selectedMonth)?.name || '';
      return [
        { text: 'Gerencia', value: 'managementName' },
        { text: `N° de acciones programadas Enero - ${monthName}`, value: 'tasksUntilSelectedMonth' },
        { text: `% programado Enero - ${monthName}`, value: 'plannedCompletionPercentage' },
        { text: `N° de acciones ejecutadas Enero - ${monthName}`, value: 'completedTasksUntilSelectedMonth' },
        { text: `% ejecutado Enero - ${monthName}`, value: 'realCompletionPercentage' },
        { text: 'Plan de acción anual totales', value: 'totalAnnualTasks' },
      ];
    },
    breadcrumbs() {
      return [
        {
          text: "Inicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "Dashboard",
          },
        },
        {
          text: "Graficos",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "DashboardActionPlan",
          },
        },
      ];
    },
    ...mapState("auth", ["user"]),
    ...mapState("security", [
      "managementsUser",
      // "costCentersUser"
      "allCostCenters",
      "allManagementsState"
    ]),
    ...mapState("action_plan", [
      "reportDashboardData"
    ])
  },
  methods: {
    ...mapActions("action_plan", [
      "getReportDashboardData"
    ]),
    ...mapActions("security", [
      "getCostCenterUser",
      "allManagements"
    ]),

    initializeFiltersFromQuery() {
      const month = this.$route.query.selectedMonth;
      // const management_id = this.$route.query.selectedManagement;

      if (month !== undefined) {
        this.selectedMonth = parseInt(month);
      }
      // if (management_id !== undefined) {
      //   this.selectedManagement = parseInt(management_id);
      // }

      // Aquí podrías hacer cualquier otra configuración necesaria
    },

    async loadData() {
      this.isLoadingData = true;
      await this.getReportData();
      this.isLoadingData = false;
    },

    // async getCostCenterByUser() {
    //   const { error } = await this.getCostCenterUser({
    //     user_id: this.user.id,
    //   });
    //   if (error) showError(error);
    // },

    async getCostCenterByUser() {
      const { error } = await this.allManagements({ active: true });
      if (error) {
        showError(error);
      } else {

        this.selectedManagementType = 'area';

        const management_id = this.$route.query.selectedManagement;

        if (management_id !== undefined) {
          const foundManagementFilter = this.allManagementsState.find(management => management.id === parseInt(management_id));

          if (foundManagementFilter) {
            this.selectedManagementType = foundManagementFilter.management_type;
          }
        }

        this.updateManagementType();
        // if (this.allManagementsState && this.allManagementsState.length > 0) {

        //   console.log('response',response)
        //   this.selectedManagement = this.allManagementsState[0]?.id;
        // }
        // this.loadData();
      }
    },

    filterCostCenters() {
      if (this.selectedManagement) {
        const management = this.managementsUser.find(m => m.id === this.selectedManagement);
        this.filteredCostCenters = management ? management.costCenters : [];
      } else {
        this.filteredCostCenters = this.allCostCenters;
      }
    },

    async getReportData() {
      this.loadingActionPlan = true;
      const data = await this.getReportDashboardData({
        month: this.selectedMonth,
        management_id: this.selectedManagement,
        service_id: this.selectedService,
        management_type: this.selectedManagementType
      });

      if (!data.ok) showError(data.error);
      this.processReportData(data.response);
      this.loadingActionPlan = false;
    },

    processReportData(data) {
      /**
       * First chart
       */
      const generalManagements = data.DashboardComplianceByManagementForSelectedMonth;
      const generalLabels = generalManagements.map(item => item.managementName);
      const overallCompletionPercentage = generalManagements.map(item => item.overallCompletionPercentage);
      const totalCompleted = generalManagements.map(item => item.totalCompleted);

      this.chartData1 = {
        labels: generalLabels,
        datasets: [
          {
            label: `% cumplimiento ${this.months[this.currentMonth - 1]?.name} - ${this.months[this.selectedMonth - 1]?.name}`,
            backgroundColor: '#00bcd4',
            data: overallCompletionPercentage,
            rawAmounts: totalCompleted,
          }
        ],
      }

      /**
       * second chart
       */
      const plannedRealComplieance = data.DashboardPlannedVsRealCompliance;
      const managementNames = plannedRealComplieance.map(item => item.managementName);
      // const chartTwoTotalAnnualTasks = plannedRealComplieance.map(item => item.totalAnnualTasks);
      const chartTwotasksUntilSelectedMonth = plannedRealComplieance.map(item => item.tasksUntilSelectedMonth);
      const chartTwoplannedCompletionPercentage = plannedRealComplieance.map(item => item.plannedCompletionPercentage);
      const chartTwocompletedTasksUntilSelectedMonth = plannedRealComplieance.map(item => item.completedTasksUntilSelectedMonth);
      const chartTworealCompletionPercentage = plannedRealComplieance.map(item => item.realCompletionPercentage);

      this.chartData2 = {
        labels: managementNames,
        datasets: [
          {
            label: `% programado ${this.months[this.currentMonth - 1]?.name} - ${this.months[this.selectedMonth - 1]?.name} vs anual`,
            backgroundColor: '#c4c4c4',
            data: chartTwoplannedCompletionPercentage,
            rawAmounts: chartTwotasksUntilSelectedMonth
          },
          {
            label: `% ejecutado ${this.months[this.currentMonth - 1]?.name} - ${this.months[this.selectedMonth - 1]?.name} vs anual`,
            backgroundColor: '#00bcd4',
            data: chartTworealCompletionPercentage,
            rawAmounts: chartTwocompletedTasksUntilSelectedMonth,
          }
        ],
      }

      this.chartOptions2 = {
        ...this.chartOptions2,
        plugins: {
          ...this.chartOptions2.plugins,
          customAnnotations: {
            ...this.chartOptions2.plugins.customAnnotations,
            // totalAmounts: chartTwoTotalAnnualTasks,
          }
        },
      }

      /**
       *  third chart
       */
      const serviceCompliance = data.DashboardServiceCompliance;
      const serviceNames = serviceCompliance.map(item => item.serviceName);
      const chartThreeCompletedTasks = serviceCompliance.map(item => item.completedTasks);
      const chartThreeCompletionPercentage = serviceCompliance.map(item => item.completionPercentage);

      this.chartData3 = {
        labels: serviceNames,
        datasets: [
          {
            label: `% cumplimiento ${this.months[this.currentMonth - 1]?.name} - ${this.months[this.selectedMonth - 1]?.name}`,
            backgroundColor: '#00bcd4',
            data: chartThreeCompletionPercentage,
            rawAmounts: chartThreeCompletedTasks,
          }
        ],
      }

      /**
       * fourth chart
       */
      const associateServicesManagement = data.AssociatedServicesToManagement;
      const associateServicesNames = associateServicesManagement.map(item => item.serviceName);
      // const chartFourthTotalAnnualTasks = associateServicesManagement.map(item => item.totalAnnualTasks);
      const chartFourthtasksUntilSelectedMonth = associateServicesManagement.map(item => item.tasksUntilSelectedMonth);
      const chartFourthplannedCompletionPercentage = associateServicesManagement.map(item => item.completionPercentage);
      const chartFourthcompletedTasksUntilSelectedMonth = associateServicesManagement.map(item => item.completedTasksUntilSelectedMonth);
      const chartFourthrealCompletionPercentage = associateServicesManagement.map(item => item.executionPercentage);

      this.chartData4 = {
        labels: associateServicesNames,
        datasets: [
          {
            label: `% programado ${this.months[this.currentMonth - 1]?.name} - ${this.months[this.selectedMonth - 1]?.name} vs anual`,
            backgroundColor: '#c4c4c4',
            data: chartFourthplannedCompletionPercentage,
            rawAmounts: chartFourthtasksUntilSelectedMonth
          },
          {
            label: `% ejecutado ${this.months[this.currentMonth - 1]?.name} - ${this.months[this.selectedMonth - 1]?.name} vs anual`,
            backgroundColor: '#00bcd4',
            data: chartFourthrealCompletionPercentage,
            rawAmounts: chartFourthcompletedTasksUntilSelectedMonth,
          }
        ],
      }

      this.chartOptions4 = {
        ...this.chartOptions4,
        plugins: {
          ...this.chartOptions4.plugins,
          customAnnotations: {
            ...this.chartOptions4.plugins.customAnnotations,
            // totalAmounts: chartFourthTotalAnnualTasks,
          }
        },
      }
    },

    addCustomPlugin() {
      const customPlugin = {
        id: 'customAnnotations',
        afterDraw: (chart) => {
          const ctx = chart.ctx
          const chartArea = chart.chartArea
          const datasets = chart.data.datasets
          const totalAmounts = chart.options.plugins.customAnnotations.totalAmounts

          if (!datasets || !Array.isArray(datasets) || datasets.length === 0) return

          datasets.forEach((dataset, datasetIndex) => {
            if (!dataset.rawAmounts || !Array.isArray(dataset.rawAmounts)) return

            dataset.rawAmounts.forEach((amount, index) => {
              const meta = chart.getDatasetMeta(datasetIndex)
              const bar = meta.data[index]
              const x = bar.x
              const y = totalAmounts ? chartArea.bottom - 65 : chartArea.bottom - 40

              ctx.save()
              ctx.beginPath()
              ctx.arc(x, y, 13, 0, 2 * Math.PI, false)
              if (datasets.length === 1) {
                ctx.fillStyle = '#ff5722'; // Single dataset color
              } else {
                ctx.fillStyle = datasetIndex === 0 ? '#ffc107' : '#ff5722'; // Multiple datasets color
              }
              ctx.fill()
              ctx.fillStyle = '#fff'
              ctx.font = 'bold 15px Arial'
              ctx.textAlign = 'center'
              ctx.textBaseline = 'middle'
              ctx.fillText(amount, x, y)
              ctx.restore()
            })
          })

          if (!totalAmounts || !Array.isArray(totalAmounts)) return;

          totalAmounts.forEach((total, index) => {
            const meta = chart.getDatasetMeta(0)
            const bar = meta.data[index]
            const x = bar.x - bar.width * 0.4
            const y = chartArea.bottom
            const width = bar.width * 1.9
            const height = 40

            ctx.save()
            ctx.beginPath()
            ctx.rect(x, y - 40, width, height)
            ctx.fillStyle = '#3f51b5'
            ctx.fill()
            ctx.fillStyle = '#ffffff'
            ctx.font = 'bold 20px Arial'
            ctx.textAlign = 'center'
            ctx.fillText(total, x + bar.width, y - 20)
            ctx.restore()
          })
        }
      }

      Chart.register(customPlugin)
    },

    redirectToDetail() {
      //TODO verificar con managmeentsUser si el management seleccionado existe en su perfil
      this.$router.push({
        name: 'ReportActionPlans',
        query: {
          month: this.selectedMonth,
          management_id: this.selectedManagement
        }
      });
    },

    updateCharts() {
      this.loadData();
    },

    updateManagementType() {

      if (this.selectedManagementType && this.allManagementsState) {
        const filterManagements = this.allManagementsState.filter(ccu => ccu.management_type === this.selectedManagementType)
        this.filteredManagements = filterManagements;

        if (filterManagements.length) {
          this.selectedManagement = filterManagements[0].id;
        }
      }
      this.updateCharts();
    },

    updateURL() {
      if (this.$route.name === 'DashboardActionPlan') {
        this.$router.push({
          query: {
            selectedMonth: this.selectedMonth,
            selectedManagement: this.selectedManagement || undefined
          }
        }).catch(err => {
          if (err.name !== "NavigationDuplicated") {
            console.error(err);
          }
        });
      }
    },

    findValueMonth(monthSelected) {
      if (monthSelected) {
        const data = this.months.find(month => month.value === monthSelected)
        return data;
      } else {
        return '-'
      }
    }
  },
  components: {
    BarChart: Bar
  },
}