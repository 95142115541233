var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-container',[_c('v-breadcrumbs',{staticClass:"px-0 pt-2 pb-4",attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])}),_c('v-container',[_c('v-card',{staticClass:"pa-4 px-md-8 mb-1",attrs:{"rounded":"lg","flat":""}},[_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h6"},[_vm._v(" Detalle de agrupación de plan de acción "+_vm._s(_vm.managementDetails?.managementName)+" / "+_vm._s(_vm.managementDetails?.costCenterName)+" ")])])],1)],1),_c('v-card',{staticClass:"mt-4",attrs:{"rounded":"lg","flat":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.actionPlanHeaders,"items":_vm.actionPlans,"items-per-page":-1,"single-expand":_vm.singleExpand,"hide-default-footer":"","expanded":_vm.expanded,"item-key":"id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:`item.status`,fn:function({ item }){return [_c('v-chip',{attrs:{"color":_vm.setColorStatusDetailActionPlan(item.status)?.background,"text-color":_vm.setColorStatusDetailActionPlan(item.status)?.color}},[_vm._v(" "+_vm._s(_vm.setColorStatusDetailActionPlan(item.status)?.name)+" ")])]}},{key:`item.actions`,fn:function({ item }){return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":{
                        name: 'DetailActionPlanSatisfaction',
                        params: {
                          serviceId: item?.service.id,
                          actionPlanId: item?.id,
                        },
                      }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Detalle")])])],1)]}},{key:"top",fn:function(){return [_c('v-row',{staticClass:"mb-2",attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Buscar plan de acción","placeholder":"Ingresar descripción de plan de acción","prepend-inner-icon":"mdi-magnify","hide-details":"","dense":"","outlined":"","clearable":""},on:{"input":_vm.debounceSearch},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.status,"item-text":"name","item-value":"value","placeholder":"Seleccionar estado","label":"Filtrar por Estado","hide-details":"","multiple":"","clearable":"","dense":"","outlined":""},on:{"input":_vm.getActionPlanByService},model:{value:(
                      _vm.actionPlanServiceFilters.status_evidence_action_plan
                    ),callback:function ($$v) {_vm.$set(_vm.actionPlanServiceFilters, "status_evidence_action_plan", $$v)},expression:"\n                      actionPlanServiceFilters.status_evidence_action_plan\n                    "}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.usersActive,"item-text":"fullName","item-value":"id","placeholder":"Seleccionar responsable","label":"Filtrar por responsable","hide-details":"","clearable":"","dense":"","outlined":""},on:{"input":_vm.getActionPlanByService},model:{value:(_vm.actionPlanServiceFilters.responsible),callback:function ($$v) {_vm.$set(_vm.actionPlanServiceFilters, "responsible", $$v)},expression:"actionPlanServiceFilters.responsible"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":_vm.monthValues,"item-text":"name","item-value":"value","placeholder":"Seleccionar mes","label":"Filtrar por mes","hide-details":"","clearable":"","dense":"","outlined":""},on:{"input":_vm.getActionPlanByService},model:{value:(_vm.actionPlanServiceFilters.month),callback:function ($$v) {_vm.$set(_vm.actionPlanServiceFilters, "month", $$v)},expression:"actionPlanServiceFilters.month"}})],1)],1)]},proxy:true},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers?.length}},[_c('v-data-table',{attrs:{"headers":_vm.evidenceActionPlanHeaders,"items":item.evidences_action_plan,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:`item.upload_date`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.upload_date))+" ")]}},{key:`item.register_date`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item?.register_date))+" ")]}},{key:`item.status`,fn:function({ item }){return [_c('v-chip',{attrs:{"color":_vm.setColorStatusDetailActionPlan(item?.status)
                          ?.background,"text-color":_vm.setColorStatusDetailActionPlan(item?.status)?.color}},[_vm._v(" "+_vm._s(_vm.setColorStatusDetailActionPlan(item?.status)?.name)+" ")])]}},{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{attrs:{"small":"","disabled":item.status === 'NO_COMPLETADO' ? true : false},on:{"click":function($event){return _vm.openModal(item)}}},[_vm._v(" mdi-eye ")])]}}],null,true)}),_c('v-dialog',{attrs:{"width":"800","max-width":"1000"},model:{value:(_vm.modalOpen),callback:function ($$v) {_vm.modalOpen=$$v},expression:"modalOpen"}},[_c('v-card',[_c('v-card-title',[_vm._v("Evidencia")]),(!_vm.selectedFile)?_c('div',[(_vm.modalItem)?_c('table',{staticClass:"custom-table ml-5"},[_c('tbody',[_c('tr',[_c('th',[_vm._v("Periodo:")]),_c('td',[_vm._v(_vm._s(_vm.formatDate(_vm.modalItem.register_date)))])]),_c('tr',[_c('th',[_vm._v("Estado:")]),_c('td',[_c('v-chip',{attrs:{"color":_vm.setColorStatusDetailActionPlan(
                                    _vm.modalItem?.status
                                  )?.background,"text-color":_vm.setColorStatusDetailActionPlan(
                                    _vm.modalItem?.status
                                  )?.color}},[_vm._v(" "+_vm._s(_vm.setColorStatusDetailActionPlan( _vm.modalItem?.status )?.name)+" ")])],1)]),(_vm.modalItem?.status === 'OBSERVADO')?_c('tr',[_c('th',[_vm._v("Comentario de observación:")]),_c('td',[_vm._v(_vm._s(_vm.modalItem.comment))])]):_vm._e(),(_vm.modalItem?.status === 'OBSERVADO')?_c('tr',[_c('th',[_vm._v("Responsable de observación:")]),_c('td',[_vm._v(_vm._s(_vm.modalItem.creator_id.fullName))])]):_vm._e(),_c('tr',[_c('th',[_vm._v("Comentario:")]),_c('td',[_vm._v(" "+_vm._s(_vm.modalItem.comment ? _vm.modalItem.comment : "-")+" ")])]),_c('tr',[_c('th',[_vm._v("Evidencias:")])])])]):_vm._e(),(
                          _vm.allImages(_vm.modalItem?.evidence_plan_action_details)
                        )?_c('div',{staticClass:"mr-4 ml-4"},[_c('v-carousel',{attrs:{"cycle":"","height":"300","hide-delimiter-background":"","show-arrows-on-hover":""}},_vm._l((_vm.modalItem?.evidence_plan_action_details),function(item){return _c('v-carousel-item',{key:item.id},[_c('v-img',{attrs:{"src":item.evidence_file,"height":"300px"}})],1)}),1)],1):_c('div',_vm._l((_vm.modalItem?.evidence_plan_action_details),function(item){return _c('div',{key:item.id},[_c('div',[(
                                _vm.isImage(item.evidence_file)
                              )?[_c('v-btn',{ref:"button",refInFor:true,staticClass:"ml-10 mb-3",staticStyle:{"width":"170px"},attrs:{"dark":"","small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.viewFile(item.evidence_file)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-eye")]),_vm._v(" Ver archivo ")],1)]:_vm._e(),(
                                !_vm.isImage(item.evidence_file) ||
                                _vm.isPDF(item.evidence_file)
                              )?_c('v-btn',{staticClass:"ml-10 mb-3",staticStyle:{"width":"170px"},attrs:{"href":item.evidence_file,"color":"primary","outlined":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-link")]),_vm._v("Descargar Archivo ")],1):_vm._e()],2)])}),0)]):_vm._e(),(_vm.selectedFile)?_c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","text":"","small":""},on:{"click":_vm.closeFileView}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" Regresar ")],1)],1),_c('div',[_c('v-chip',{attrs:{"href":_vm.selectedFile,"color":"primary","outlined":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-link")]),_vm._v("Descargar Archivo ")],1)],1)]),(_vm.isImage(_vm.selectedFile))?_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":_vm.selectedFile,"alt":_vm.selectedFile}}):(_vm.isPDF(_vm.selectedFile))?_c('pdf',{attrs:{"src":_vm.selectedFile}}):_vm._e()],1):_vm._e(),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{ref:"button",attrs:{"dark":"","small":"","color":"primary"},on:{"click":_vm.closeModal}},[_vm._v("Cerrar ")])],1)],1)],1)],1)]}}],null,true)}),_c('div')],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }