import moment from 'moment';
export default {
    name:"ActionPlanItem",
    props:{
        value:Boolean,
        actionPlan:Object
    },
    data() {
      return {
        message: 'Hola, este es un mensaje básico en Vue.js!',
      };
    },

    methods:{
        formatDate(dateString) {
			if (!dateString) {
				return '-';
			}
			return moment(dateString).format('DD/MM/YYYY');
		},

        setColorStatusActionPlan(status) {
            switch (status) {
              case "EN_CURSO":
                return {
                  background: "blue lighten-5",
                  color: "blue",
                  name: "EN CURSO",
                };
              case "COMPLETADO":
                return {
                  background: "green lighten-5",
                  color: "green",
                  name: status,
                };
              case "ATRASADO":
                return {
                  background: "grey lighten-3",
                  color: "grey",
                  name: status,
                };
              case "ANULADO":
                return {
                  background: "grey lighten-2",
                  color: "grey",
                  name: status,
                };
            }
          },
    }
  };