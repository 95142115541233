var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-container',[_c('v-breadcrumbs',{staticClass:"px-0 pt-2 pb-4",attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])})],1),_c('v-container',[_c('v-card',{attrs:{"flat":"","loading":_vm.isLoading}},[_c('v-card-title',[_vm._v("Detalle de evidencia de plan de acción")]),_c('v-container',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[(_vm.evidenceActionPlan)?_c('table',{staticClass:"custom-table"},[_c('tbody',[_c('tr',[_c('th',[_vm._v("Plan de acción:")]),_c('td',[_vm._v(_vm._s(_vm.evidenceActionPlan?.action_plan?.description))])]),_c('tr',[_c('th',[_vm._v("Responsable de plan de acción:")]),_c('td',[_vm._v(_vm._s(_vm.evidenceActionPlan?.action_plan?.responsible.fullName))])]),_c('tr',[_c('th',[_vm._v("Servicio:")]),_c('td',[_vm._v(_vm._s(_vm.evidenceActionPlan?.action_plan?.service.name))])]),_c('tr',[_c('th',[_vm._v("Responsable de subida de evidencia:")]),_c('td',[_vm._v(_vm._s(_vm.evidenceActionPlan?.creator_id?.fullName))])]),_c('tr',[_c('th',[_vm._v("Estado:")]),_c('td',[_vm._v(_vm._s(_vm.evidenceActionPlan?.status))])]),(_vm.evidenceActionPlan?.status === 'OBSERVADO')?_c('tr',[_c('th',[_vm._v("Comentario de observación:")]),_c('td',[_vm._v(_vm._s(_vm.evidenceObservedData.comment))])]):_vm._e(),(_vm.evidenceActionPlan?.status === 'OBSERVADO')?_c('tr',[_c('th',[_vm._v("Responsable de observación:")]),_c('td',[_vm._v(_vm._s(_vm.evidenceObservedData.creator_id.fullName))])]):_vm._e(),_c('tr',[_c('th',[_vm._v("Periodo:")]),_c('td',[_vm._v(_vm._s(_vm.formatDate(_vm.evidenceActionPlan?.register_date)))])]),(
                    _vm.evidenceActionPlan?.evidence_plan_action_details.length
                  )?_c('tr',[_c('th',[_vm._v("Evidencias:")]),_c('td',[_c('div',_vm._l((_vm.evidenceActionPlan?.evidence_plan_action_details),function(item){return _c('v-chip',{key:item.id,attrs:{"color":"primary","outlined":"","small":"","target":"_blank"},on:{"click":function($event){return _vm.detailEvidence()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-link")]),_vm._v(" Archivo ")],1)}),1)])]):_vm._e()])]):_vm._e()]),(
              _vm.evidenceActionPlan?.status === 'PENDIENTE' &&
              _vm.isAllowed(
                'climaLaboral',
                'reportePlanAccion',
                'aprobarEvidencia'
              )
            )?_c('v-col',{staticClass:"d-flex justify-end align-end",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-4 mb-4",attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.observedEvidence()}}},[_vm._v("Observar evidencia")]),_c('v-btn',{staticClass:"primary mr-4 mb-4",on:{"click":function($event){return _vm.confirmEvidence()}}},[_vm._v("Aprobar evidencia")])],1):_vm._e()],1)],1)],1)],1),_c('evidence-action-plan-detail-dialog',{attrs:{"item":_vm.selectedItem},model:{value:(_vm.detailEvidenceDialog),callback:function ($$v) {_vm.detailEvidenceDialog=$$v},expression:"detailEvidenceDialog"}}),_c('observed-evidence-dialog',{attrs:{"item":_vm.evidenceActionPlan,"source":_vm.$route.params.source ? _vm.$route.params.source : null},on:{"getEvidenceActionPlan":_vm.getEvidenceActionPlan},model:{value:(_vm.observedEvidenceDialog),callback:function ($$v) {_vm.observedEvidenceDialog=$$v},expression:"observedEvidenceDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }