<template>
	<DetailActionPlan />
</template>
<script>

import DetailActionPlan from '@/components/action-plans/detail-action-plan/index.vue'

export default {
	name: 'DetailActionPlanPage',
	components: { DetailActionPlan },
};
</script>
