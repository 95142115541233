import { mapActions, mapState } from "vuex";
import DatesSelectedDialog from "@/components/action-plans/components/selectedDatesField/index.vue";
import QuestionsSelectedDialog from "../components/selectedQuestionsDialog";
import { showSnackBar } from "@/helpers/globalHelpers";
import moment from "moment";

export default {
  name: "CreateActionPlan",

  data: () => ({
    loadingSend: false,
    searchQuestion: "",
    isValidForm: null,
    selectedDates: [],
    selectedQuestions: [],
    isLoadingUsers: false,
    isLoadingManagements: false,
    requiredRule: [(v) => !!v || "Campo requerido"],
    formAP: {
      company_id: parseInt(localStorage.getItem("company_id")),
      service_id: null,
      description: "",
      responsible: null,
      recipients: null,
    },
    questionsHeader: [
      { text: "Factores", sortable: false, value: "factor.name" },
      { text: "Subfactores", sortable: false, value: "sub_factor.name" },
      { text: "Preguntas", sortable: false, value: "description" },
      { text: "", sortable: false, value: "actions", align: "right" },
    ],
  }),

  created() {
    this.loadUsers();
    this.loadManagements();
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("security", [
      "usersActive",
      "costCentersUser"
      //  "costCenters"
    ]),
    breadcrumbs() {
      return [
        {
          text: "Inicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "Dashboard",
          },
        },
        {
          text: "Planes de acción",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "ListActionPlanEvidences",
          },
        },
        {
          text: "Crear plan de acción",
          link: true,
          exact: true,
          disabled: true,
        },
      ];
    },
  },

  methods: {
    ...mapActions("security", [
      "getUsersActive",
      "getCostCenterUser",
      //  "getCostCenter"
    ]),
    ...mapActions("action_plan", ["createActionPlan"]),

    loadUsers: async function () {
      this.isLoadingUsers = true;
      await this.getUsersActive();
      this.isLoadingUsers = false;
    },

    loadManagements: async function () {
      this.isLoadingManagements = true;
      await this.getCostCenterUser({ user_id: this.user.id });
      this.isLoadingManagements = false;
    },

    removeQuestion: function (questionId) {
      const index = this.selectedQuestions.findIndex(
        (e) => e?.id === questionId
      );
      if (index > -1) this.selectedQuestions.splice(index, 1);
    },

    validationForm: function () {
      const formCompleted = this.$refs.form.validate();
      const periodCompleted = this.selectedDates.length > 0;
      const questionCompleted = this.selectedQuestions.length > 0;

      if (!formCompleted) {
        showSnackBar("Completar el formulario", "warning");
        return false;
      }

      if (!periodCompleted) {
        showSnackBar("Completar el período", "warning");
        return false;
      }

      if (!questionCompleted) {
        showSnackBar("Seleccionar las preguntas", "warning");
        return false;
      }

      if (formCompleted && periodCompleted && questionCompleted) {
        this.saveActionPlan();
      }
    },

    saveActionPlan: async function () {
      this.loadingSend = true;
      let data = {
        ...this.formAP,
        questions: [],
        periodicity: [],
      };

      this.selectedDates.forEach((date) => {
        data.periodicity.push({
          period_date: moment(date).toISOString(),
        });
      });

      this.selectedQuestions.forEach((question) => {
        data.questions.push(question?.id);
      });

      const { ok, error } = await this.createActionPlan(data);
      if (ok) {
        this.$swal.fire({
          title: "¡El plan de acción fue creado con éxito!",
          text: "En un momento te enviaremos la aprobación por correo para que puedas subir tu evidencia, gracias",
          icon: "success",
          confirmButtonColor: "#0039a6",
          confirmButtonText: "Ok",
        });
        this.$router.push({ name: "ListActionPlanEvidences",/* params: { serviceId: this.$route.params.serviceId }*/ });
      } else {
        let errorMsm = error?.data?.message;
        errorMsm = Array.isArray(errorMsm) ? errorMsm.join(" <br> ") : errorMsm;
        showSnackBar(errorMsm, "error");
      }
      this.loadingSend = false;
    },
  },

  components: {
    DatesSelectedDialog,
    QuestionsSelectedDialog,
  },
};
