import { mapActions, mapState } from "vuex";
import DatesSelectedDialog from "@/components/action-plans/components/selectedDatesField/index.vue";
import FactorSelectedDialog from "../components/factor-selected-dialog";
import { showSnackBar } from "@/helpers/globalHelpers";
import moment from "moment";

export default {
  name: "CreateActionPlanSatisfaction",

  data: () => ({
    loadingSend: false,
    searchFactor: "",
    isValidForm: null,
    selectedDates: [],
    selectedFactors: [],
    previousServiceId: null,
    isLoadingUsers: false,
    isLoadingManagements: false,
    requiredRule: [(v) => !!v || "Campo requerido"],
    formAP: {
      company_id: parseInt(localStorage.getItem("company_id")),
      service_id: null,
      description: "",
      responsible: null,
      recipients: null,
    },

    factorsHeader: [
      { text: "Factores de evaluación", sortable: false, width: '30%', value: "description" },
      { text: "Peso", sortable: false, value: "weight" },
      { text: "Puntaje", sortable: false, value: "score", width: '120px', editable: true },
      { text: "Observaciones y/o comentarios", sortable: false, value: "comments", editable: true },
      { text: "Ponderado", sortable: false, width: '120px', value: "weightedScore" },
      { text: "", sortable: false, value: "actions", align: "right" },
    ],
  }),

  created() {
    this.loadUsers();
    this.loadManagements();
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("security", [
      "usersActive",
      "costCentersUser"
      //  "costCenters"
    ]),
    breadcrumbs() {
      return [
        {
          text: "Inicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "Dashboard",
          },
        },
        {
          text: "Planes de acción",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "ListActionPlanEvidencesSatifaction",
          },
        },
        {
          text: "Crear plan de acción",
          link: true,
          exact: true,
          disabled: true,
        },
      ];
    },
  },

  watch: {
    'formAP.service_id': {
      handler(newVal, oldVal) {
        if (newVal === null || newVal === undefined) {
          this.selectedFactors = [];
        } else if (newVal !== oldVal) {
          if (!this.cancelingChange) {
            this.previousServiceId = oldVal;
          }
          this.cancelingChange = false;
        }
      },
      immediate: false
    }
  },

  methods: {
    ...mapActions("security", [
      "getUsersActive",
      "getCostCenterUser",
      //  "getCostCenter"
    ]),
    ...mapActions("action_plan", ["createActionPlan"]),

    loadUsers: async function () {
      this.isLoadingUsers = true;
      await this.getUsersActive();
      this.isLoadingUsers = false;
    },

    loadManagements: async function () {
      this.isLoadingManagements = true;
      await this.getCostCenterUser({ user_id: this.user.id });
      this.isLoadingManagements = false;
    },

    removeQuestion: function (questionId) {
      const index = this.selectedFactors.findIndex(
        (e) => e?.id === questionId
      );
      if (index > -1) this.selectedFactors.splice(index, 1);
    },

    validationForm: function () {
      const formCompleted = this.$refs.form.validate();
      const periodCompleted = this.selectedDates.length > 0;
      const questionCompleted = this.selectedFactors.length > 0;

      if (!formCompleted) {
        showSnackBar("Completar el formulario", "warning");
        return false;
      }

      if (!periodCompleted) {
        showSnackBar("Completar el período", "warning");
        return false;
      }

      if (!questionCompleted) {
        showSnackBar("Seleccionar factores", "warning");
        return false;
      }

      if (formCompleted && periodCompleted && questionCompleted) {
        this.saveActionPlan();
      }
    },

    saveActionPlan: async function () {
      this.loadingSend = true;
      let data = {
        ...this.formAP,
        type: 'SATISFACCION_CLIENTE',
        satisfactionDetails: [],
        periodicity: [],
      };

      this.selectedDates.forEach((date) => {
        data.periodicity.push({
          period_date: moment(date).toISOString(),
        });
      });
      console.log('selected factors',this.selectedFactors)
      this.selectedFactors.forEach((factor) => {
        data.satisfactionDetails.push({
          satisfaction_criteria_id: factor.id,
          description: factor.description,
          weight:factor.weight,
          score:parseInt(factor.score),
          observations:factor.comments,
          pondered:factor.weightedScore
        });
      });

      const { ok, error } = await this.createActionPlan(data);
      if (ok) {
        this.$swal.fire({
          title: "¡El plan de acción fue creado con éxito!",
          text: "En un momento te enviaremos la aprobación por correo para que puedas subir tu evidencia, gracias",
          icon: "success",
          confirmButtonColor: "#0039a6",
          confirmButtonText: "Ok",
        });
        this.$router.push({ name: "ListActionPlanEvidencesSatifaction", });
      } else {
        let errorMsm = error?.data?.message;
        errorMsm = Array.isArray(errorMsm) ? errorMsm.join(" <br> ") : errorMsm;
        showSnackBar(errorMsm, "error");
      }
      this.loadingSend = false;
    },

    calculateWeightedScore(item) {
      if (item.weight && item.score) {
        item.weightedScore = Math.round((item.weight * item.score) * 100) / 100;
      }
    },

    handleServiceChange(newServiceId) {
      if (this.selectedFactors.length > 0 && this.formAP.service_id) {
        this.$swal.fire({
          title: '¿Estás seguro de seleccionar otro servicio?',
          text: 'Seleccionar otro servicio limpiará los factores seleccionados. ¿Deseas continuar?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#0039a6',
          confirmButtonText: 'Sí, cambiar',
          cancelButtonText: 'No, cancelar'
        }).then((result) => {
          if (result.isConfirmed) {
            this.selectedFactors = [];
            this.formAP.service_id = newServiceId;
          } else {
            this.cancelingChange = true;
            this.formAP.service_id = this.previousServiceId;
          }
        });
      } else {
        this.formAP.service_id = newServiceId;
      }
    }
  },

  components: {
    DatesSelectedDialog,
    FactorSelectedDialog,
  },
};
