import { mapState, mapActions } from "vuex";
import { showError } from "@/helpers/globalHelpers";
import ObservedEvidenceDialog from '../../action-plans/components/observedDialog';
import EvidenceActionPlanDetailDialog from '../../action-plans/components/evidenceActionPlanDetail';
import moment from "moment";
export default {
  name: "DetailEvidenceActionPlanEvidence",
  data() {
    return {
      isLoading: false,
      loadingApproveEvidence: false,
      observedEvidenceDialog: false,
      selectedItem :null,
      detailEvidenceDialog: false,
    }
  },

  computed: {
    breadcrumbs() {
        console.log('route',this.$route.params.source)
      return this.$route.params.source === 'evidenceActionPlanSatisfaction' ?
        [
          {
            text: "Inicio",
            link: true,
            exact: true,
            disabled: false,
            to: {
              name: "Dashboard",
            },
          },
          {
            text: "Lista de planes de acción por servicio",
            link: true,
            exact: true,
            disabled: false,
            to: {
              name: "ReportActionPlansSatisfaction",
            },
          },
          {
            text: "Lista de evidencias de plan de acción",
            link: true,
            exact: true,
            disabled: false,
            to: {
              name: 'ListEvidenceActionPlanSatisfaction',
            },
          },
          {
            text: "Detalle de evidencia de plan de acción",
            link: true,
            exact: true,
            disabled: true,

          },
        ] :
        [
          {
            text: "Inicio",
            link: true,
            exact: true,
            disabled: false,
            to: {
              name: "Dashboard",
            },
          },
          {
            text: "Lista de planes de acción por servicio",
            link: true,
            exact: true,
            disabled: false,
            to: {
              name: "ReportActionPlansSatisfaction",
            },
          },
          {
            text: "Detalle de agrupación de planes de acción por servicio",
            link: true,
            exact: true,
            disabled: false,
            to: {
              name: "DetailEvidenceActionPlanSatisfaction",
              params: { serviceId: this.$route.params.serviceId }
            },
          },
          {
            text: "Detalle de plan de acción",
            link: true,
            exact: true,
            disabled: false,
            to: {
              name: 'DetailActionPlan',
              params: { serviceId: this.$route.params.serviceId, actionPlanId: this.$route.params.actionPlanId }
            },
          },
          {
            text: "Detalle de evidencia de plan de acción",
            link: true,
            exact: true,
            disabled: true,

          },
        ];
    },
    ...mapState("evidence_action_plan", ["evidenceActionPlan"]),
    evidenceObservedData(){
      if(this.evidenceActionPlan?.status === 'OBSERVADO' && this.evidenceActionPlan?.evidence_action_plan_history_status && this.evidenceActionPlan?.evidence_action_plan_history_status.length){
       
        const length = this.evidenceActionPlan?.evidence_action_plan_history_status.length;
  
        const evidenceActionPlanObserved = this.evidenceActionPlan?.evidence_action_plan_history_status[length-1];
     
        return evidenceActionPlanObserved;
      }else{
        return null;
      }
    }
  },

  created() {
    this.getEvidenceActionPlan();
  },

  methods: {
    ...mapActions("evidence_action_plan", ["getEvidenceActionPlanById", "cleanEvidenceActionPlanId", "confirmObservedEvidenceActionPlan"]),

    async getEvidenceActionPlan() {
      this.isLoading = true;
      const { evidenceActionPlanId } = this.$route.params;
      const { error } = await this.getEvidenceActionPlanById(evidenceActionPlanId);
      if (error) showError(error);
      this.isLoading = false;
    },

    formatDate(dateString) {
      // if (!dateString) {
      //   return { dateOnly: "-", isToday: false };
      // }
      // const formattedDateTime = moment(dateString).format("DD/MM/YYYY HH:mm");
      // const dateOnly = formattedDateTime.split(" ")[0];
      // const isToday = moment().format("DD/MM/YYYY") === dateOnly; // Comparar con la fecha actual

      // return { dateOnly, isToday };

      if (!dateString) {
        return "-";
      }
      // Trata la fecha como un objeto de fecha pura sin conversión de zona horaria
      const dateOnly = moment.utc(dateString).format("DD/MM/YYYY");

      return dateOnly;
    },

    observedEvidence() {
      // this.editedItem = Object.assign({}, item);
      this.observedEvidenceDialog = true;
      this.$nextTick(() => {
        document.activeElement.blur();
      });
    },

    confirmEvidence() {
      this.$swal({
        text: '¿Estás seguro de aprobar esta evidencia?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0039a6',
        cancelButtonColor: 'grey',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sí',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { evidenceActionPlanId } = this.$route.params;
          const data = {
            company_id: parseInt(localStorage.getItem("company_id")),
            evidence_action_plan_id: evidenceActionPlanId,
            observed: false
          }

          const { error } = await this.confirmObservedEvidenceActionPlan(data);
          if (error) showError(error);

          this.$route.params.source !== 'evidenceActionPlan' ? this.getEvidenceActionPlan() : this.$router.push({ name: "ListEvidenceActionPlan" });
        }
      })
    },

    isAllowed(module, page, activityName) {

      const activities = this.$store.state.auth.activities || [];
      const found = activities.some(
        (activity) =>
          activity.module_name === module &&
          activity.page_name === page &&
          activity.activity_name === activityName
      );

      return found;
    },

    detailEvidence() {
      this.selectedItem = this.evidenceActionPlan;
      this.detailEvidenceDialog = true;
      this.$nextTick(() => {
        document.activeElement.blur();
      });
    }
  },

  beforeDestroy() {
    this.cleanEvidenceActionPlanId();
  },

  components: {
    ObservedEvidenceDialog,
    EvidenceActionPlanDetailDialog
  }
}