import { mapActions, mapState } from "vuex";

export default {
  name: "SelectedFactorsDialog",
  data() {
    return {
      dialog: false,
      loadingSave: false,
      listError: [],
      questionsHeader: [
        { text: "Factores de evalución", sortable: false, value: "description" },
        { text: "Peso", sortable: false, value: "weight" },
        { text: "", sortable: false, value: "actions" },
      ],
      loadingFactors: false,
      searchFactor: null,
      filters: {
        description: null,
        service_id: null,
      },
      searchTimeout: null,
    };
  },
  props: {
    value: Array,
    serviceId: Number
  },
  computed: {
    ...mapState("factor_action_plan",["allFactors"]),
    selectedFactors: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  created() { },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.filters = {
          description: null,
        };
        this.getFactors();
      }
    },
  },
  methods: {
    ...mapActions("factor_action_plan",["listAllFactors"]),
    close() {
      this.dialog = false;
    },
    async deleteItemConfirm() {
      console.log("");
    },

    getFactors: async function () {
      this.loadingFactors = true;
      if (this.serviceId) {
        this.filters.service_id = this.serviceId;
      }
      await this.listAllFactors(this.filters);
      this.loadingFactors = false;
    },

    isFactorSelected: function (factorId) {
      return this.selectedFactors.some((e) => e?.id == factorId);
    },

    addFactor: function (factor) {
      this.selectedFactors.push(factor);
    },

    removeFactor: function (factorId) {
      const index = this.selectedFactors.findIndex(
        (e) => e?.id === factorId
      );
      if (index > -1) this.selectedFactors.splice(index, 1);
    },

    debounceSearch() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.filters.description = this.searchFactor;
        this.getFactors();
      }, 800);
    },
  },
};
