<template>
	<UploadEvidenceActionPlanSatisfaction />
</template>
<script>

import UploadEvidenceActionPlanSatisfaction from '@/components/satisfaction-action-plans/upload-evidence-action-plan-satisfaction/index.vue'

export default {
	name: 'UploadEvidenceActionPlanSatisfactionPage',
	components: { UploadEvidenceActionPlanSatisfaction },
};
</script>
