<template>
	<DetailActionEvidencePlan />
</template>
<script>

import DetailActionEvidencePlan from '@/components/action-plans/detail-evidence-action-plan/index.vue'

export default {
	name: 'DetailActionEvidencePlanPage',
	components: { DetailActionEvidencePlan },
};
</script>
