<template>
	<DetailActionPlanSatisfactionGroupedService />
</template>
<script>

import DetailActionPlanSatisfactionGroupedService from '@/components/satisfaction-action-plans/detail-action-plan-satisfaction-grouped-service/index.vue'

export default {
	name: 'DetailActionPlanSatisfactionGroupedServicePage',
	components: { DetailActionPlanSatisfactionGroupedService },
};
</script>
