import { mapState, mapActions } from "vuex";
import { showError } from "@/helpers/globalHelpers";
export default {
  name: "ListActionPlans",

  data: () => ({
    loadingCostCenter: false,
    loadingActionPlan: false,
    headers: [
      { text: "Plan de acción", sortable: false, value: "description" },
      { text: "Responsable", sortable: false, value: "responsible.fullName" },
      { text: "Servicio", sortable: false, value: "service.name" },
      { text: "Gerencia", sortable: false, value: "management.name" },
      { text:  "Estado", sortable:false,value:"status"},
      // { text: "Gerencia", sortable: false, value: "area.name" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    actionPlanFilters: {
      page: 1,
      limit: process.env.VUE_APP_PAGINATION_PER_PAGE || 10,
      pagination: true,
      //
      service_ids: null,
      creator_id: null,
    },
  }),

  created() {
    this.getCostCenterByUser();
    this.getActionPlan();
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: "Inicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "Dashboard",
          },
        },
        {
          text: "Planes de acción por servicio",
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: "ListActionPlanEvidences",
          },
        },
        {
          text: "Planes de acción",
          link: true,
          exact: true,
          disabled: true,
          // to: {
          //   name: 'InventoryHome',
          // },
        },
      ];
    },
    ...mapState("auth", ["user"]),
    ...mapState("security", [
      // "managements", 
      // "costCenters"
      "costCentersUser"
    ]),
    ...mapState("action_plan", ["actionPlansPagination", "actionPlans"]),
    title() {
      let message;
      const { serviceId } = this.$route.params;
      const allServiceVerified = this.actionPlans?.every(actionPlan => actionPlan?.service?.id == serviceId)
      if (allServiceVerified) {
        message = `Lista de planes de acción de ${this.actionPlans[0]?.service.name}`
        return message
      } else {
        message = 'Lista de planes de acción'
        return message;
      }
    }
  },

  methods: {
    ...mapActions("security", [
      // "getManagements",
      "getCostCenterUser"
    ]),

    ...mapActions("action_plan", ["listActionPlanPagination", "deletedActionPlan"]),

    async getCostCenterByUser() {
      this.loadingCostCenter = true;
      const { error, response } = await this.getCostCenterUser({
        user_id: this.user.id,
      });
      console.log(response);
      if (error) showError(error);
      this.loadingCostCenter = false;
    },

    async getActionPlan() {
      this.loadingActionPlan = true;
      const { serviceId } = this.$route.params;
      this.actionPlanFilters.service_ids = serviceId;
      this.actionPlanFilters.creator_id = this.user.id;
      const { error } = await this.listActionPlanPagination(
        this.actionPlanFilters
      );
      if (error) showError(error);
      this.loadingActionPlan = false;
    },

    evidencesObserved(item) {
      const observed = item?.evidences_action_plan.some(evidenceActionPlan => evidenceActionPlan.status === 'OBSERVADO');
      return observed;
    },

    confirmDelete(actionPlanId) {
      this.$swal({
        text: '¿Estás seguro de eliminar este plan de acción?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0039a6',
        cancelButtonColor: 'grey',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sí',
      }).then(async (result) => {
        if (result.isConfirmed) {
        
          const { error } = await this.deletedActionPlan({
            id: actionPlanId,

          });

          if (error) showError(error);
          this.getActionPlan()
        }
      })
    },

    setColorStatusActionPlan(status) {
      switch (status) {
        case "EN_CURSO":
          return {
            background: "blue lighten-5",
            color: "blue",
            name: "EN CURSO",
          };
        case "COMPLETADO":
          return {
            background: "green lighten-5",
            color: "green",
            name: status,
          };
        case "ATRASADO":
          return {
            background: "grey lighten-3",
            color: "grey",
            name: status,
          };
        case "ANULADO":
          return {
            background: "grey lighten-2",
            color: "grey",
            name: status,
          };
      }
    },

    isAllowed(module, page, activityName) {

      const activities = this.$store.state.auth.activities || [];
      const found = activities.some(
        (activity) =>
          activity.module_name === module &&
          activity.page_name === page &&
          activity.activity_name === activityName
      );
     
      return found;
    }
  },
};
