<template>
	<EvidencesActionPlan />
</template>
<script>

import EvidencesActionPlan from '@/components/action-plans/list-evidences-action-plan/index.vue'

export default {
	name: 'EvidencesActionPlanPage',
	components: { EvidencesActionPlan },
};
</script>
