<template>
	<ListEvidenceActionPlanSatisfaction />
</template>
<script>

import ListEvidenceActionPlanSatisfaction from '@/components/satisfaction-action-plans/list-evidences-action-plan-satisfaction/index.vue'

export default {
	name: 'ListEvidenceActionPlanSatisfactionPage',
	components: { ListEvidenceActionPlanSatisfaction },
};
</script>
