<template>
	<ListActionPlanSatisfaction />
</template>
<script>

import ListActionPlanSatisfaction from '@/components/satisfaction-action-plans/list-action-plan-satisfaction/index.vue'

export default {
	name: 'ListActionPlanSatisfactionPage',
	components: { ListActionPlanSatisfaction },
};
</script>
