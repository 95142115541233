<template>
	<ListActionPlansService />
</template>
<script>

import ListActionPlansService from '@/components/action-plans/list-action-plans-service/index.vue'

export default {
	name: 'ListActionPlansServicePage',
	components: { ListActionPlansService },
};
</script>
