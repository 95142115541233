import moment from 'moment';
export default {
    name: 'EvidenceActionPlanDetail',
    data() {
        return {
            selectedFile: null,
        };
    },
    props: {
        value: Boolean,
        item: Object,
    },
    created() {

    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    methods: {
        dialogInput(value) {
            this.$nextTick(() => {
                this.$refs.dialogForm.$el.blur();
                this.$refs.form.resetValidation();
                this.dialog = value;
            })
        },
        formatDate(dateString) {

            if (!dateString) {
              return "-";
            }
      
            const dateOnly = moment.utc(dateString).format("DD/MM/YYYY");
      
            return dateOnly;
          },
          setColorStatusDetailActionPlan(status) {
            switch (status) {
              case "EN_CURSO":
                return {
                  background: "blue lighten-5",
                  color: "blue",
                  name: "EN CURSO",
                };
              case "PENDIENTE":
                return {
                  background: "blue lighten-5",
                  color: "blue",
                  name: "PENDIENTE",
                }
              case "COMPLETADO":
                return {
                  background: "green lighten-5",
                  color: "green",
                  name: status,
                };
              case "OBSERVADO":
                return {
                  background: "yellow lighten-4",
                  color: "yellow darken-2",
                  name: status,
                };
              case "NO_COMPLETADO":
                return {
                  background: "grey lighten-3",
                  color: "grey",
                  name: "NO COMPLETADO",
                };
            }
          },
        isImage(file) {
            const extension = this.getFileExtension(file);
            return ['jpg', 'jpeg', 'png', 'gif'].includes(extension);
        },
        isPDF(file) {
            const extension = this.getFileExtension(file);
            return extension === 'pdf';
        },
        allImages(evidences) {
            return evidences?.every(item => this.isImage(item.evidence_file));
        },
        viewFile(file) {
            this.selectedFile = file;
        },
        closeFileView() {
            this.selectedFile = null;
        },
        getFileExtension(file) {
            return file.split('.').pop().toLowerCase();
        },
        closeModal() {
            this.dialog = false;
            this.selectedFile = null;
        },
    },
};
