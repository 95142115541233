<template>
	<ListActionPlans />
</template>
<script>

import ListActionPlans from '@/components/action-plans/list-action-plans/index.vue'

export default {
	name: 'ListActionPlansPage',
	components: { ListActionPlans },
};
</script>
